import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"
// import './prueba.css'
import { useColorMode } from 'theme-ui'
import sun from "../../content/assets/sun.png"
import moon from "../../content/assets/moon.png"
const checkedIcon = (
  <img
    alt="moon indicating dark mode"
    src={moon}
    width="16"
    height="16"
    role="presentation"
    css={{
      pointerEvents: `none`,
      margin: 4,
    }}
  />
)

const uncheckedIcon = (
  <img
    alt="sun indicating light mode"
    src={sun}
    width="16"
    height="16"
    role="presentation"
    css={{
      pointerEvents: `none`,
      margin: 4,
    }}
  />
)

function Layout({ location, title, children }) {
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  // const { theme, toggleTheme } = useTheme();
  const [colorMode, setColorMode] = useColorMode()

  function getTheme() {
    if (colorMode === "light") {
      typeof window !== "undefined" && document.documentElement.style
        .setProperty('--bg-color', '#FFF');
      return checkedIcon
    }
    else {
      typeof window !== "undefined" && document.documentElement.style
        .setProperty('--bg-color', '#12181B');
      return uncheckedIcon
    }
  }
  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>{header}
      </header>
      <Link
        style={{ color: `#007acc00` }}
        to={location.pathname}
        id="toggletheme"
        aria-label="Indicating light or dark mode"
        name="toggletheme"
        onClick={e => {
          setColorMode(colorMode === 'light' ? 'dark' : 'light')
        }}>
        {getTheme()}
      </Link>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
          {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </footer>
    </div>
  )

}


export default Layout
